@import '../base/mixins';

#report-wrapper,
#compound-report-wrapper {
    &.wrapper {
        @include scroll(false, false, false, 8px, false, var(--grey-300), false, 17px);
        position: relative;
        margin-top: 20px;
        padding-right: 20px;
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        row-gap: 30px;

        &.with-sticky-header {
            row-gap: 20px;
        }

        .sticky-header {
            position: sticky;
            top: 0;
            z-index: 1;
            padding-bottom: 10px;
            background-color: var(--grey-50);

            @include organization(dmi) {
                background-color: var(--white);
            }
        }

        .sections-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
        }
    }
}
